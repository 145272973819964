<template>
  <v-dialog
    v-model="myitem"
    persistent
    scrollable
    width="1000"
  >
    <v-card>
      <base-app-dialog-title
        @close="close"
      >
        <v-tabs
          v-model="tab"
        >
          <v-tab>
            User settings
          </v-tab>
          <v-tab>
            Agency roles
          </v-tab>
        </v-tabs>
      </base-app-dialog-title>
      <v-card-text class="pt-0">
        <v-tabs-items v-model="tab">
          <v-tab-item
            key="1"
          >
            <v-card flat>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row class="ma-1">
                    <v-col cols="6">
                      <base-app-text-field
                        v-model="myitem.firstName"
                        label="First name"
                        :rules="requiredRule"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <base-app-text-field
                        v-model="myitem.lastName"
                        label="Last name"
                        :rules="requiredRule"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <base-app-text-field
                        v-model="myitem.username"
                        label="Username"
                        :rules="emailRule"
                        required
                        placeholder="test@example.com"
                        :disabled="!isAdmin"
                      />
                    </v-col>
                    <v-col cols="6">
                      <base-app-text-field
                        v-model="myitem.password"
                        label="New password"
                        :rules="passwordRule"
                        :disabled="!isAdmin"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPassword = !showPassword"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
            key="2"
          >
            <v-card flat>
              <v-card-text>
                <v-row
                  v-for="(agency, idx) in agenciesWithRoles"
                  :key="idx"
                  align="center"
                >
                  <v-col cols="6">
                    <span class="font-weight-light">{{ agency.name }}</span>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="agency.roles"
                      :items="agencyRoles"
                      max-width="500"
                      label="Roles"
                      multiple
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          :color="item.color"
                          outlined
                        >
                          <span>{{ item.text }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="2">
                    <base-app-dialog-action-button
                      :disabled="!(newAgency && newRoles)"
                      @click="addRoles"
                    >
                      Add role
                    </base-app-dialog-action-button>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="newAgency"
                      :items="sortedAgencies"
                      item-text="name"
                      item-value="id"
                      max-width="500"
                      label="Agency"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="newRoles"
                      :items="agencyRoles"
                      max-width="500"
                      label="Roles"
                      multiple
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          small
                          :color="item.color"
                          outlined
                        >
                          <span>{{ item.text }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <base-app-dialog-action-button
          :disabled="!valid"
          @click="update"
        >
          Update
        </base-app-dialog-action-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import { eventBus, busEvents } from '@/EventBus'
  import { userRoleEnums } from '@/constants'
  export default {
    name: 'EditUserDialog',
    data () {
      return {
        newAgency: null,
        newRoles: null,
        valid: false,
        tab: 0,
        showPassword: false,

        orgItem: JSON.parse(JSON.stringify(this.$store.state.internaladmin.userAgencyRoles)),
        myitem: JSON.parse(JSON.stringify(this.$store.state.internaladmin.userAgencyRoles)),
        /*
        orgItem: JSON.parse(JSON.stringify(this.userAgencyRoles || {})),
        myitem: JSON.parse(JSON.stringify(this.userAgencyRoles || {})),
        */
        requiredRule: [
          v => !!v || 'required',
        ],
        emailRule: [
          v => !!v || false,
          v => /\S+@\S+\.\S+/.test(v) || 'invalid email',

        ],
        agencyRoles: userRoleEnums,
      }
    },
    computed: {
      ...mapGetters({
        userAgencyRoles: 'internaladmin/USER_AGENCY_ROLES',
        isAdmin: 'app/IS_ADMIN',
      }),
      passwordRule () {
        if (this.isAdmin) {
          return [
            v => (!v || (v && v.length >= 8)) || 'min 8 characters',
            v => (!v || (v && v.length <= 30)) || 'max 30 characters',
            v => (!v || (v && /^(?=.*[a-z]).{8,}$/.test(v))) || 'lower case character required',
            v => (!v || (v && /^(?=.*[A-Z]).{8,}$/.test(v))) || 'upper case character required',
            v => (!v || (v && /^(?=.*[0-9]).{8,}$/.test(v))) || 'number required',
            v => (!v || (v && /^(?=.*[^a-zA-Z0-9]).{8,}$/.test(v))) || 'special character required',
          ]
        } else return []
      },
      agenciesWithRoles () {
        return this.myitem.agencies
          .filter(e => e.roles.length)
          .sort((a, b) => a.name.localeCompare(b.name))
      },
      sortedAgencies () {
        return this.myitem.agencies
          .filter(e => e.name)
          .sort((a, b) => a.name.localeCompare(b.name))
      },
    },
    mounted () {
      // this.$refs.form.validate()
    },
    methods: {
      addRoles () {
        // Update roles
        const agency = this.myitem.agencies.find(e => e.id === this.newAgency)
        agency.roles = this.newRoles
        // Nullify select fields
        this.newAgency = null
        this.newRoles = null
      },
      ...mapMutations({
        updateInternalAdmins: 'internaladmin/UPDATE_INTERNAL_ADMIN',
        setUserAgencyRoles: 'internaladmin/SET_USER_AGENCY_ROLES',
      }),
      update () {
        const { agencies, id, firstName, lastName, username, password } = this.myitem
        const orgAgencies = this.orgItem.agencies

        const data = []
        agencies.forEach(a => {
          a.roles.forEach(role => {
            data.push({ agencyId: a.id, roleId: role })
          })
        })

        const orgData = []
        orgAgencies.forEach(a => {
          a.roles.forEach(role => {
            orgData.push({ agencyId: a.id, roleId: role })
          })
        })

        // filter what is removed and added compared to original data
        const add = data.filter(o => !orgData.some(i => (i.agencyId === o.agencyId && i.roleId === o.roleId)))
        const remove = orgData.filter(o => !data.some(i => (i.agencyId === o.agencyId && i.roleId === o.roleId)))

        const fname = firstName !== this.orgItem.firstName ? firstName : null
        const lname = lastName !== this.orgItem.lastName ? lastName : null
        const uname = username !== this.orgItem.username ? username : null
        const pword = password !== this.orgItem.password ? password : null

        const req = {
          user: {
            ...(fname && { firstName: fname }),
            ...(lname && { lastName: lname }),
            ...(uname && { username: uname }),
            ...(pword && { password: pword }),
          },
          roles: {
            add,
            remove,
          },
        }

        this.$careConnect.updateUser(id, req)
          .then(resp => {
            const text = 'User updated'
            this.updateInternalAdmins(resp)
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'success', text })
            this.setUserAgencyRoles(null)
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to update user'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
          })
      },
      close () {
        this.setUserAgencyRoles(null)
      },
    },

  }
</script>
<style scoped>
.scrollable {
  overflow-y:scroll;
}
</style>
