<template>
  <v-container
    id="internaladmins"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="text-right"
      >
        <v-dialog
          v-model="dialog"
          persistent
          width="800"
          eager
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              rounded
              depressed
              color="aluminium"
              class="text-h4"
              v-bind="attrs"
              v-on="on"
            >
              Add admin
              <v-icon
                right
                large
                color="primaryTeal"
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-form
              ref="ffa"
              v-model="valid"
            >
              <base-app-dialog-title
                title="ADD ADMIN"
                @close="dialog = false"
              />
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <base-app-text-field
                          v-model="firstName"
                          label="First name"
                          :rules="requiredRule"
                          required
                          @focus="adminError = null"
                        />
                      </v-col>
                      <v-col cols="6">
                        <base-app-text-field
                          v-model="lastName"
                          label="Last name"
                          :rules="requiredRule"
                          required
                          @focus="adminError = null"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <base-app-text-field
                          v-model="username"
                          label="Username"
                          :rules="emailRule"
                          required
                          placeholder="test@example.com"
                          @focus="adminError = null"
                        />
                      </v-col>
                      <v-col cols="6">
                        <base-app-text-field
                          v-model="password"
                          label="Password"
                          :rules="passwordRule"
                          required
                          placeholder="test@example.com"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                          @focus="adminError = null"
                          @click:append="showPassword = !showPassword"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="adminError"
                  align="center"
                  justify="center"
                >
                  <span class="red--text text-subtitle-1">{{ adminError }}</span>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <base-app-dialog-action-button
                  :disabled="!valid"
                  @click="createAdmin"
                >
                  Create
                </base-app-dialog-action-button>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <edit-user-dialog
        v-if="userAgencyRoles"
      />
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                cols="3"
              >
                <base-app-text-field
                  v-model="search"
                  label="search"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="internalAdmins"
              :search="search"
              class="cursor-pointer"
              @click:row="editRoles"
            >
              <template v-slot:item.created="{ item }">
                <span>{{ new Date(item.created).toLocaleString() }}</span>
              </template>
              <template v-slot:item.lastModified="{ item }">
                <span>{{ new Date(item.lastModified).toLocaleString() }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import { eventBus, busEvents } from '@/EventBus'
  import EditUserDialog from './component/internaladmin/EditUserDialog.vue'

  export default {
    name: 'InternalAdminsView',
    components: {
      'edit-user-dialog': EditUserDialog,
    },
    data () {
      return {
        adminError: null,
        showPassword: false,
        firstName: null,
        lastName: null,
        username: null,
        password: null,
        dialog: false,
        valid: false,
        search: '',
        requiredRule: [
          v => !!v || false,
        ],
        emailRule: [
          v => !!v || false,
          v => /\S+@\S+\.\S+/.test(v) || 'invalid email',

        ],
        passwordRule: [
          v => !!v || false,
          v => (v && v.length >= 8) || 'min 8 characters',
          v => (v && v.length <= 30) || 'max 30 characters',
          v => (v && /^(?=.*[a-z]).{8,}$/.test(v)) || 'lower case character required',
          v => (v && /^(?=.*[A-Z]).{8,}$/.test(v)) || 'upper case character required',
          v => (v && /^(?=.*[0-9]).{8,}$/.test(v)) || 'number required',
          v => (v && /^(?=.*[^a-zA-Z0-9]).{8,}$/.test(v)) || 'special character required',
        ],
        headers: [
          {
            sortable: true,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: true,
            text: 'firstName',
            value: 'firstName',
          },
          {
            sortable: true,
            text: 'lastName',
            value: 'lastName',
          },
          {
            sortable: true,
            text: 'Username',
            value: 'username',
          },
          {
            sortable: true,
            text: 'created',
            value: 'created',
          },
          {
            sortable: true,
            text: 'Last modified',
            value: 'lastModified',
          },
          {
            sortable: true,
            text: 'State',
            value: 'state',
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        internalAdmins: 'internaladmin/INTERNAL_ADMINS',
        userAgencyRoles: 'internaladmin/USER_AGENCY_ROLES',
      }),
    },
    watch: {
      dialog (visible) {
        if (visible) {
          this.$refs.ffa.validate()
        } else {
          this.$refs.ffa.resetValidation()
          this.firstName = null
          this.lastName = null
          this.password = null
          this.username = null
          this.adminError = null
          this.showPassword = false
        }
      },
    },
    mounted () {
      // this.loadData()
    },
    methods: {
      ...mapMutations('app', {
        setLoading: 'SET_LOADING',
      }),
      ...mapMutations('internaladmin', {
        setInternalAdmins: 'SET_INTERNAL_ADMINS',
        addInternalAdmin: 'ADD_INTERNAL_ADMIN',
        setUserAgencyRoles: 'SET_USER_AGENCY_ROLES',
      }),
      loadData () {
        this.setLoading(true)
        this.$careConnect.getInternalAdmins()
          .then(resp => {
            this.setInternalAdmins(resp)
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to load internal admins'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      createAdmin () {
        this.setLoading(true)
        const { firstName, lastName, username, password } = this
        this.$careConnect.createInternalAdmin({
          firstName,
          lastName,
          username: username.toLowerCase(),
          password,
        })
          .then(resp => {
            this.addInternalAdmin(resp)
            this.dialog = false
            const text = 'User created'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'success', text })
          })
          .catch((err) => {
            this.adminError = err.json ? err.json.error : 'Failed to add admin'
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      editRoles (el) {
        this.setLoading(true)
        this.$careConnect.getUserAgencyRoles(el.id)
          .then(resp => {
            this.setUserAgencyRoles(resp)
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to load admin data'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
    },
  }
</script>

<style>
.cursor-pointer tbody tr:hover {
  cursor: pointer;
}
</style>
